import classNames from 'classnames';
import React, { useState } from 'react';
import { ImageSize } from '@ecg-marktplaats/media-util-js-lib';
import { Trans, useI18nContext } from '@ecg-marktplaats/js-react-i18n';
import {
  Card,
  ListBasic,
  ListItemWithImage,
  AvatarImage,
  Text,
  MaskedLink,
  StarRating,
  Icon,
  Link,
  Block,
  BasicModal,
} from '@hz-design-system/web-ui';
import average from '../../utils/average';
import getNumberOrZero from '../../utils/getNumberOrZero';

import Map from '../Map/Map';
import redirectToSellerWebsiteUrl from '../../utils/redirectToSellerWebsiteUrl';
import imageSizeFormatter from '../../utils/imageSizeFormatter';
import { daysNames, today } from '../../utils/weekDays';

import Classes from './ProfileSidebar.scss';

const { Regular } = Text;

const WebsiteUrl = ({ url }: { url: string }) => {
  const i18n = useI18nContext();

  return (
    <li className={Classes.infoWrap}>
      <Icon name="website" className={Classes['infoWrap-icon']} />
      <MaskedLink href={url} className={Classes['infoWrap-link']} target="_blank" rel="noopener noreferrer">
        {i18n.t('sellerProfile.websiteUrl.label')}
      </MaskedLink>
    </li>
  );
};

const EmailInfo = ({ email }: { email: string }) => (
  <li className={Classes.infoWrap}>
    <Icon name="email" className={Classes['infoWrap-icon']} />
    <Link href={`mailto:${email}`}>{email}</Link>
  </li>
);

const PhoneNumberInfo = ({ phoneNumber }: { phoneNumber: string | number }) => (
  <li className={Classes.infoWrap}>
    <Icon name="call" className={Classes['infoWrap-icon']} />
    <Link href={`tel:${phoneNumber}`}>{phoneNumber}</Link>
  </li>
);

function isOpeningHoursSet<T extends Record<any, unknown>>(openingHours: T | undefined) {
  return openingHours && Object.keys(openingHours).some((key) => openingHours[key]);
}

const OpeningHours = ({ openingHours }: { openingHours: any }) => {
  const i18n = useI18nContext();

  return (
    <div className={Classes.openingHoursPopupWrap}>
      {Object.keys(openingHours)
        .sort((a, b) => daysNames[a as keyof typeof daysNames] - daysNames[b as keyof typeof daysNames])
        .map((key) => (
          <div className={Classes['openingHoursPopupWrap-item']} key={key}>
            <span className={Classes['openingHoursPopupWrap-day']}>
              {i18n.t(`sellerProfile.businessHours.${key}`)}:
            </span>
            {!openingHours[key].closed ? (
              <span className={Classes['openingHoursPopupWrap-time']}>
                {openingHours[key].openFrom} - {openingHours[key].openTill}
              </span>
            ) : (
              <span className={Classes['openingHoursPopupWrap-time']}>
                {i18n.t('sellerProfile.businessHours.closed')}
              </span>
            )}
          </div>
        ))}
    </div>
  );
};

const SellerCompanyLegalInfo = ({
  sellerCompanyLegalInfo,
}: {
  sellerCompanyLegalInfo: {
    name: string;
    btw: string;
    kvk: string;
  };
}) => {
  const i18n = useI18nContext();

  return sellerCompanyLegalInfo.name || sellerCompanyLegalInfo.btw || sellerCompanyLegalInfo.kvk ? (
    <Card kind="rounded" className={Classes.wrap}>
      <div className={Classes.card}>
        <div className={Classes.cardSection}>
          <div className={Classes.sellerCompanyLegalInfo}>
            <div className={Classes['sellerCompanyLegalInfo-label']}>
              {i18n.t('sellerProfile.companyLegalInfo.label')}
            </div>
            {sellerCompanyLegalInfo.name && (
              <div className={Classes['sellerCompanyLegalInfo-item']}>
                <Trans
                  i18nKey="sellerProfile.companyLegalInfo.name"
                  tagName="span"
                  className={Classes['sellerCompanyLegalInfo-itemLabel']}
                />
                <span className="ProfileSidebar-sellerCompanyLegalInfo-itemContent">{sellerCompanyLegalInfo.name}</span>
              </div>
            )}
            {sellerCompanyLegalInfo.btw && (
              <div className={Classes['sellerCompanyLegalInfo-item']}>
                <Trans
                  i18nKey="sellerProfile.companyLegalInfo.btw"
                  tagName="span"
                  className={Classes['sellerCompanyLegalInfo-itemLabel']}
                />
                <span className="ProfileSidebar-sellerCompanyLegalInfo-itemContent">{sellerCompanyLegalInfo.btw}</span>
              </div>
            )}
            {sellerCompanyLegalInfo.kvk && (
              <div className={Classes['sellerCompanyLegalInfo-item']}>
                <Trans
                  i18nKey="sellerProfile.companyLegalInfo.kvk"
                  tagName="span"
                  className={Classes['sellerCompanyLegalInfo-itemLabel']}
                />
                <span className="ProfileSidebar-sellerCompanyLegalInfo-itemContent">{sellerCompanyLegalInfo.kvk}</span>
              </div>
            )}
          </div>
        </div>
      </div>
    </Card>
  ) : null;
};

const LocationInfo = ({ sellerAddress }: { sellerAddress: { street: string; postCode: string; city: string } }) => (
  <div className={Classes.locationWrap}>
    <Icon name="location" className={Classes['locationWrap-icon']} />
    <div className={Classes.sellerAddress}>
      <span>{sellerAddress.street}</span>
      <span>{`${sellerAddress.postCode} ${sellerAddress.city}`}</span>
    </div>
  </div>
);

const ProfileImage = ({ logoImage, sellerTitle }: { logoImage: string; sellerTitle: string }) => (
  <div className={Classes.profileImageWrap}>
    <img src={logoImage} alt={sellerTitle} className={Classes.profileImage} />
  </div>
);

const ProfileStaticMap = ({ latitude, longitude }: { latitude: number; longitude: number }) => (
  <div className={Classes.mapContainer}>
    <Map
      latitude={latitude}
      longitude={longitude}
      center={{ lat: latitude, lng: longitude }}
      zoom={11}
      options={{ draggable: false }}
      standardMarkerProps={{
        clickable: true,
      }}
    />
  </div>
);

const SalesRepresentativesBlock = ({ salesRepresentatives }: { salesRepresentatives: any[] }) => {
  const i18n = useI18nContext();
  const formattedUrl = (url: string) => imageSizeFormatter({ imageUrlFromApi: url, customSize: ImageSize.Medium });

  if (!salesRepresentatives.length) {
    return null;
  }

  return (
    <Block className={Classes.salesRepsSection}>
      <ListBasic title={i18n.t('sellerProfile.salesRepsTitle')}>
        {salesRepresentatives.map((rep, index) => (
          <ListItemWithImage
            key={`list-item-${index}`}
            image={<AvatarImage src={formattedUrl(rep.photo?.base)} alt="image" placeholderIconName="profile" />}
          >
            <Regular color="TextPrimary">{rep.name}</Regular>
            <Regular color="TextTertiary">{rep.title}</Regular>
          </ListItemWithImage>
        ))}
      </ListBasic>
    </Block>
  );
};

const CarDealerReviewsBlock = ({
  carDealerReviews = { lastReviews: [] },
  onClickReviewsControl,
}: {
  carDealerReviews: ProfileSideBarProps['carDealerReviews'];
  onClickReviewsControl: () => void;
}) => {
  const i18n = useI18nContext();

  const { numberOfReviews, avgOverallScore, lastReviews = [] } = carDealerReviews;

  const averageScore = average(getNumberOrZero(avgOverallScore));
  const showStarRating = Boolean(numberOfReviews || averageScore);
  const lastReview = lastReviews[0];
  const showBlock = showStarRating || lastReview;
  const reviewsLabel = i18n.t('sellerProfile.review', { count: numberOfReviews });

  if (!showBlock) {
    return null;
  }

  return (
    <Card kind="rounded" className={Classes.wrap}>
      {showStarRating && (
        <Block onClick={onClickReviewsControl} className={Classes.ratingWrap}>
          <StarRating label={`(${numberOfReviews}) ${reviewsLabel}`} rating={averageScore} />
        </Block>
      )}
      {lastReview && (
        <Block className={Classes.review}>
          <>
            <p>{lastReview.text}</p>
            <p className={Classes.textRight}>- {lastReview.name}</p>
          </>
        </Block>
      )}
    </Card>
  );
};

const BusinessHoursTodayFormatted = ({
  businessHoursToday,
}: {
  businessHoursToday: {
    closed: boolean;
    openFrom: string;
    openTill: string;
  };
}) => {
  const i18n = useI18nContext();

  if (businessHoursToday && !businessHoursToday.closed && businessHoursToday.openFrom && businessHoursToday.openTill) {
    return (
      <span>
        {i18n.t('sellerProfile.businessHours.todayFromTill', {
          openFrom: businessHoursToday.openFrom,
          openTill: businessHoursToday.openTill,
        })}
      </span>
    );
  }
  return <span>{i18n.t('sellerProfile.businessHours.todayClosed')}</span>;
};

type ProfileSideBarProps = {
  businessHours?: Record<
    any,
    {
      openFrom: string;
      openTill: string;
      closed: boolean;
    }
  >;
  carDealerReviews?: {
    numberOfReviews?: number;
    avgOverallScore?: number;
    lastReviews?: any[];
  };
  companyRegistrationData: {
    btw: string;
    kvk: string;
    name: string;
  };
  contactInformation: {
    city: string;
    postCode: string;
    street: string;
    phoneNumber: string;
    email?: string;
  };
  dsaFlags: {
    publicInfoProvided: boolean;
    approved: boolean;
  };
  hasGeolocation: boolean;
  onClickReviewsControl: () => void;
  salesRepresentatives: any[];
  sellerGeolocation: {
    city: string;
    country: string;
    countryAbbreviation: string;
    latitude: number;
    longitude: number;
  };
  sellerId: number;
  sellerTermsAndConditions?: string;
  sellerTitle: string;
  logoImage: string;
  websiteUrl: string;
};
const ProfileSidebar = (props: ProfileSideBarProps) => {
  const {
    dsaFlags,
    sellerId,
    sellerTitle,
    logoImage,
    contactInformation: { email, city, postCode, street, phoneNumber } = {},
    businessHours,
    hasGeolocation,
    sellerGeolocation,
    websiteUrl,
    carDealerReviews,
    onClickReviewsControl,
    salesRepresentatives = [],
    companyRegistrationData,
  } = props;

  const i18n = useI18nContext();

  const [isBusinessHoursPopupOpen, setBusinessHoursOpen] = useState(false);

  const businessHoursToday = businessHours && businessHours[today];

  return (
    <>
      {businessHours && isBusinessHoursPopupOpen && (
        <BasicModal
          title={i18n.t('sellerProfile.businessHours.label')}
          isBottomSheet
          cancel={() => setBusinessHoursOpen(false)}
          onClose={() => setBusinessHoursOpen(false)}
        >
          <Block className={Classes.content}>
            <OpeningHours openingHours={businessHours} />
          </Block>
        </BasicModal>
      )}
      {Boolean(logoImage || websiteUrl || phoneNumber || email) && (
        <Card kind="rounded" className={Classes.wrap}>
          <div className="u-textStyleBodyRegularStrong u-paddingXAxisS u-paddingTopS">
            {i18n.t('sellerProfile.contactInformation')}
          </div>
          <Block className={Classes.cardSection}>
            {logoImage && <ProfileImage logoImage={logoImage} sellerTitle={sellerTitle} />}
            <ul>
              {websiteUrl && <WebsiteUrl url={redirectToSellerWebsiteUrl(sellerId)} />}
              {phoneNumber && <PhoneNumberInfo phoneNumber={phoneNumber} />}
              {dsaFlags.approved && email && <EmailInfo email={email} />}
            </ul>
          </Block>
          <SalesRepresentativesBlock salesRepresentatives={salesRepresentatives} />
        </Card>
      )}
      {(Boolean(postCode && street && city) || hasGeolocation || isOpeningHoursSet(businessHours)) && (
        <Card kind="rounded" className={Classes.wrap}>
          <div className={Classes.card}>
            {postCode && street && city && (
              <div className={Classes['cardSection-address']}>
                <LocationInfo sellerAddress={{ postCode, street, city }} />
              </div>
            )}
            {isOpeningHoursSet(businessHours) && (
              <div className={Classes.cardSection}>
                <div className={Classes.openingHoursWrap}>
                  {businessHoursToday && (
                    <div className={Classes.openingHours}>
                      <Icon name="clock" className={Classes['infoWrap-icon']} />
                      <BusinessHoursTodayFormatted businessHoursToday={businessHoursToday} />
                    </div>
                  )}
                  <div className={Classes.linkWrapper}>
                    <Link onClick={() => setBusinessHoursOpen(true)}>
                      {i18n.t('sellerProfile.seeBusinessHours.label')}
                    </Link>
                  </div>
                </div>
              </div>
            )}
            {hasGeolocation && (
              <ProfileStaticMap latitude={sellerGeolocation.latitude} longitude={sellerGeolocation.longitude} />
            )}
          </div>
        </Card>
      )}
      {dsaFlags.approved && (
        <Card kind="rounded" className={Classes.wrap}>
          <div className={Classes.card}>
            <div className={classNames(Classes.cardSection, Classes.alignCenter)}>
              <Icon className={Classes.dsaApprovedIcon} name="checkmark-circled" />
              <Text.Regular>{i18n.t('sellerProfile.dsa.approved')}</Text.Regular>
            </div>
          </div>
        </Card>
      )}
      {companyRegistrationData && <SellerCompanyLegalInfo sellerCompanyLegalInfo={companyRegistrationData} />}
      {carDealerReviews && (
        <CarDealerReviewsBlock carDealerReviews={carDealerReviews} onClickReviewsControl={onClickReviewsControl} />
      )}
    </>
  );
};

export default ProfileSidebar;
