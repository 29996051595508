import React from 'react';
import { hydrateRoot } from 'react-dom/client';
import { I18nContextProvider } from '@ecg-marktplaats/js-react-i18n';
import { Header } from '@hz-design-system/web-ui';
import SellerProfilePageApp from '../../apps/SellerProfilePage/SellerProfile';

import '../../../scss/site/global/global.scss';

export default (i18n) => {
  // eslint-disable-next-line no-underscore-dangle
  const appState = window.__CONFIG__ || {};
  const environment = window.__ENV_CONFIG__ || {};
  const headerConfig = window.__HEADER_CONFIG__ || {};
  const rootContainer = document.getElementById('root');
  const headerRootContainer = document.getElementById('header-root');

  // Header is server side rendered in express-react library
  // todo: Add I18nContext wrapper here and in express-react as well
  hydrateRoot(headerRootContainer, <Header headerConfig={headerConfig} />);

  hydrateRoot(
    rootContainer,
    <I18nContextProvider i18nInstance={i18n}>
      <SellerProfilePageApp appState={appState} environment={environment} />
    </I18nContextProvider>,
  );
};
