import React from 'react';
import { Card } from '@hz-design-system/web-ui';
import TopSection from './TopSection/TopSection';
import ProfileHeroTabs from './ProfileHeroTabs/ProfileHeroTabs';
import Info from './Info/Info';
import Classes from './ProfileHero.scss';

const ProfileHero = ({
  isUserViewingOwnProfile,
  sellerProfile,
  editProfileUrl,
  loggedInUserId,
  onClickReviewLink,
  onClickSaveControl,
  saveControlLoading,
  isSellerSaved,
  userReviews,
  carDealerReviews,
  onClickReviewsControl,
  location,
}) => {
  return (
    <section className={`${Classes.root} mp-Page-element mp-Page-element--full-width`}>
      <Card>
        <TopSection {...sellerProfile} />
        <Info
          sellerId={sellerProfile.id}
          isUserViewingOwnProfile={isUserViewingOwnProfile}
          editProfileUrl={editProfileUrl}
          sellerLocation={sellerProfile.location}
          isSellerSaved={isSellerSaved}
          periodSinceRegistrationDate={sellerProfile.periodSinceRegistrationDate}
          isSaveSellerControlVisible={loggedInUserId !== sellerProfile.id}
          onClickReviewLink={onClickReviewLink}
          onClickSaveControl={onClickSaveControl}
          saveControlLoading={saveControlLoading}
          userReviews={userReviews}
          carDealerReviews={carDealerReviews}
          onClickReviewsControl={onClickReviewsControl}
          location={location}
          hasSellerProfile={sellerProfile.hasSellerProfile}
        />
        {sellerProfile.hasSellerProfile && (
          <div className={Classes.tabWrap}>
            <ProfileHeroTabs sellerId={sellerProfile.id} sellerName={sellerProfile.title} />
          </div>
        )}
      </Card>
    </section>
  );
};

export default ProfileHero;
