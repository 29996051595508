const NAME_SPACE = 'mp';

/**
 * @param input
 * @param prefix
 * @returns {string | *}
 */
const format = (prefix = '', input = []) =>
  input
    .filter((value) => Boolean(value))
    .map((value) => `${prefix}${value}`)
    .join(' ');

/**
 * @param {Object} input - Object describing the component to be styled
 * @param {string} input.name
 * @param {string[]} [input.states]
 * @param {string[]} [input.modifiers]
 * @param {string[]} [input.utilities]
 * @param {string} [input.icon]
 * @param {string} [input.custom]
 * @returns {string}
 */
export default (input = {}) => {
  const component = `${NAME_SPACE}-${input.name}`;
  const modifiers = format(`${component}--`, input.modifiers);
  const states = format('is-', input.states);
  const utilities = format('u-', input.utilities);
  const icon = format('mp-svg-', [input.icon]);
  const custom = input.custom || '';

  return `${component} ${modifiers} ${states} ${utilities} ${icon} ${custom}`.replace(/  +/g, ' ').trim();
};

// todo: use @ecg-marktplaats/aurora-jsx-components fn
